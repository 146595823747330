* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.item {
  position: relative;
  margin: 10px;
  cursor: pointer;
  width: 144px;
  padding: 4px;
}

.item.selected {
  border: 1px solid #1378f9;
  border-radius: 4px;
  outline-offset: 5px;
}
.item.selected .media_check {
  height: 20px;
  width: 48px;
  background-color: #1378f9;
  box-shadow: 0 0 4px #0009;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  position: absolute;
  top: 6px;
  left: 8px;
}
.itemx {
  width: 98%;
  padding:5px;
/*  border:"2px solid #ccc";
  padding:"10px"; */
}
.itemx.selected {
  /* border: 2px solid red; */
  outline: 2px solid #1890ff;
  outline-offset: 5px;
}
/* .selected {
  position: relative;
  border-color:rgb(19, 120, 249);
}
.selected .media_check {
  height: 20px;
  width: 48px;
  background-color: #1378f9;
  box-shadow: 0 0 4px #0009;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  position: absolute;
  top: 6px;
  left: 8px;
} */

.cover {
  width: 100%;
  padding-top: 56.25%;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}

.duration {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #eeeeee;
  padding: 0 4px;
  border-radius: 4px;
}

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-tabs.ant-tabs-top {
  overflow: auto;
}
.wyjia{
	display:none
}
.TrackContainer_material_block__1K0T1:hover .wyjia{
	display:block
}
/* .ant-upload-list{
	height: 600px;
	overflow-y: scroll;
} */

.ant-modal-content .ant-modal-header {
  background-color: #000;
}